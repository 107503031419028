export enum Environments {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

export const ENV_VARS = {
  environment: import.meta.env.VITE_ENVIRONMENT as Environments,
  node: import.meta.env.NODE_ENV,
  release: import.meta.env.VITE_RELEASE,
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  },
  sentry: {
    dns: import.meta.env.VITE_SENTRY_DSN_BACKOFFICE,
  },
  mid: {
    templateUrl: import.meta.env.VITE_IMPORT_MID_TEMPLATE_URL,
  },
} as const;
